<template>
  <div>
    <el-row>
      <el-col :span="24" class="center">
        <el-input
          v-model="search"
          @focus="focus"
          @blur="blur"
          @keyup.enter.native="searchHandler"
          placeholder="搜索"
          prefix-icon="el-icon-search"
          @input="searchEvent"
          @keyup.up.native="_handleUp"
          @keyup.down.native="_handleDown"
        >
          <el-button slot="append" id="search" @click="searchHandler"
            >搜索</el-button
          >
        </el-input>
        <!---设置z-index优先级,防止被走马灯效果遮挡-->

        <!-- 搜索下拉框 start  -->
        <!-- @mouseenter="enterSearchBoxHanlder" -->
        <el-card
          v-if="isSearch"
          class="box-card"
          id="search-box"
          style="position:absolute;z-index:15"
        >
          <!-- <dl v-if="isHistorySearch">
            <dt class="search-title" v-show="history">历史搜索</dt>
            <dt
              class="remove-history"
              v-show="history"
              @click="removeAllHistory"
            >
              <i class="el-icon-delete" aria-hidden="true"   ></i>清空历史记录
            </dt>
            <el-tag
              v-for="search in historySearchList"
              :key="search.id"
              closable
              :type="search.type"
              @close="closeHandler(search)"
              style="margin-right:5px; margin-bottom:5px;"
              >{{ search.name }}</el-tag
            >
            <dt class="search-title">热门搜索</dt>
            <dd v-for="search in hotSearchList" :key="search.id">
              {{ search }}
            </dd>
          </dl> -->
          <dl id="menu-list">
            <dd v-for="(search,i) in searchList" :key="search.id"
            :class="{'is-active': i===activeIndex,'selectActive': i===selectIndex}"
            @click="seachValFun(search.name)"
            >
            {{ search.name }}</dd>
          </dl>
        </el-card>

         <!-- 搜索下拉框 end  -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  SearchProduct,
} from "api/product.js";
export default {
  data() {
    return {
      search: "", //当前输入框的值
      isFocus: false, //是否聚焦
      // isSearch:false,
      searchList:[],
      selectIndex:0,
      activeIndex:0
    };
  },
  props:{
       isSearch:{
         type:Boolean
       }
     },
  created() {
    var lett = this;
    document.onkeydown = function() {
        var key = window.event.keyCode;
        if (key == 13) {
         lett.searchHandler()
        }
    }
  },
  methods: {
   // 此时实时搜索，调取接口
    searchEvent(){
      this.clearTimer();
      // if($route.path)
      if (this.search && this.search.length > 0) {
        //获取当前延时函数的ID，便于后面clearTimeout清除该ID对应的延迟函数
        this.timer = setTimeout(() => {
              const parmesData = {
                  //暂无
                  keyword: this.search,
                  current: 1,
                  size: 10
                };
              SearchProduct(parmesData).then((res) => {
                if(res.data.data.records.length>0){
                    // this.isSearch=true
                    this.$emit('searchFun',true);
                    this.searchList= res.data.data.records;

                }else{
                  //  this.isSearch=false
                    this.$emit('searchFun',false);
                   this.searchList=[]
                }
                // historySearchList
                // this.searchProducts = records;
                // this.total = Number(res.data.data.total);
              });
        }, 500);
      } else {
          // this.isSearch=false
          this.$emit('searchFun',false);
               this.searchList=[]
      }
    },

_handleUp(){
            //  实现对样式的替换
            this.selectIndex = this.selectIndex === 0 ? this.searchList.length - 1 : this.selectIndex - 1;

            let selectNum = this.selectIndex + 1;
            let search = document.getElementById("menu-list");
            let intervalTop = search.scrollTop;
this.search=this.searchList[selectNum-1].name;
            //  实现在用户操作是,滚轮随着上下移的动
            if (selectNum * 30 <= intervalTop){

                search.scrollTop = (selectNum - 1) * 30;

            } else if (selectNum * 30 <= intervalTop + search.offsetHeight){

                search.scrollTop = intervalTop;

            } else if (selectNum * 30 > intervalTop){

                search.scrollTop = selectNum * 30;
            }

        },
    _handleDown(){
            this.selectIndex = this.selectIndex < this.searchList.length - 1 ? this.selectIndex + 1 : 0;
            let selectNum = this.selectIndex + 1;
            let search = document.getElementById("menu-list");
            let intervalTop = search.scrollTop;
this.search=this.searchList[selectNum-1].name;
            if (selectNum * 30 <= intervalTop){

                search.scrollTop = (selectNum - 1) * 30;

            } else if (selectNum * 30 <= intervalTop + search.offsetHeight){

                search.scrollTop = intervalTop;

            } else if (selectNum * 30 > intervalTop){

                search.scrollTop = selectNum * 30 - search.offsetHeight;
            }

        },

    clearTimer() {
          if (this.timer) {
            clearTimeout(this.timer);
          }
    } ,
    focus() {
      this.isFocus = true;
      this.searchEvent()
    },
    blur() {
      var self = this;
      this.searchBoxTimeout = setTimeout(function() {
        self.isFocus = false;
      }, 300);
    },
    seachValFun(seachName){
          this.$emit('searchFun',false);
        this.searchList=[]
        this.search=seachName
        localStorage.setItem("searchVal", this.search);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:this.search}})
    },
    searchHandler() {
      if(this.$route.path=="/ordersumbit"){
       return false
      } 
      if(this.search){
        this.$emit('searchFun',false);
        this.searchList=[]
        localStorage.setItem("searchVal", this.search);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:this.search}})
      }else{
        this.$message({
            message:'请输入搜索值',
            type:"warning"
        })
      }

    },
  },
  computed: {
  },
};
</script>

<style scoped lang="less">
@import '~style/index.less';

.center {
  margin-top: 10px;
  margin-left: 0px;
  /deep/ .el-input__inner{
        border-color:#FFD303;
            height: 38px;
    line-height: 38px;
    border-radius:0px;
  }
 
}
#search {
  background-color: #FFD303;
  border-radius: 0%;
  color: #000;
  font-size: 14px;
}
.search-title {
  color: #606266;
  font-size: 15px;
  margin-bottom: 5px;
}
.remove-history {
  color: #bdbaba;
  font-size: 15px;
  float: right;
  margin-top: -22px;
}
#search-box {
  width: 100%;
  height:auto;
  margin-top: 0px;
  padding-bottom:10px;
  font-size: 13px;
  dl{
    dd{
      line-height: 30px;
      font-size:15px;
      color: #666;
      &:hover{
        cursor: pointer;
        font-weight: bold;
        color: #303133;
      }
      &.selectActive{
        font-weight: bold;
        color: #303133;
      }
    }
  }

}
.el-input__inner {
  border: 1px solid #FFD303;
  font-size: 12px;
}
</style>
